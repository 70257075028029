import React from 'react'
import _ from 'lodash'

import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const dummySkills = [
    {
        "title": "Tech", 
        "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        "title": "Learning", 
        "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        "title": "Working", 
        "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    }
];

const dummyPlats = [
    { "name": "ABC" },
    { "name": "ABC" },
    { "name": "ABC" },
    { "name": "ABC" },
    { "name": "ABC" },
    { "name": "ABC" },
    { "name": "ABC" }
];

const dummyTools = [
    { "name": "XYZ" },
    { "name": "XYZ" },
    { "name": "XYZ" },
    { "name": "XYZ" },
    { "name": "123" },
    { "name": "123" },
    { "name": "123" },
    { "name": "123" },
    { "name": "NMO" },
    { "name": "NMO" },
    { "name": "NMO" },
    { "name": "NMO" },
    { "name": "OPZ" },
    { "name": "OPZ" },
    { "name": "OPZ" },
    { "name": "OPZ" }
];

const useStyles = makeStyles(theme => ({
  root: {
  }
}))

const Skills = ({ data }) => {
  const classes = useStyles();

  const skills = dummySkills;
  const platforms = dummyPlats;
  const tools = dummyTools;

  // The tools list shlould be divided into columns (max 4)
  const toolsColListSize = Math.ceil(tools.length/4);

  return (
    <Box className= {classes.root}>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.skillsContainer}
        >
            {/* Highlighted skills */}
            {skills.map((skill,idx)=>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    key={idx}
                    className={classes.skill}
                >  
                    <Box>
                        <Typography variant="h3" component="h3">{skill.title}</Typography>
                        <Typography variant="body1" component="p">{skill.body}</Typography>
                    </Box>

                </Grid>
            )}
            {/* Services & Platforms */}
            <Grid
                item
                xs={12}
                sm={6}
                className={classes.skill}
            >  
                <Box>
                    <Typography variant="h3" component="h3">Platforms &amp; Services</Typography>
                    <List dense>
                    {platforms.map((plat,idx)=>(
                        <ListItem key={idx}>
                            <ListItemText
                                primary={plat.name}
                            />
                        </ListItem>
                    ))}
                    </List>
                </Box>
            </Grid>
        </Grid>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={classes.toolsContainer}
        >
            {/* Tools & Tech */}
            <Grid
                item
                xs={12}
                className={classes.toolsRow}
            >  
                <Box>
                    <Typography variant="h3" component="h3">Tools &amp; Tech</Typography>
                </Box>
            </Grid>


            <Grid
                item
                xs={12}
                className={classes.toolsRow}
            >  
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.toolsListContainer}
                >
                    {[0,1,2,3].map(column=>
                        <Grid
                            item
                            key={column}
                            xs={12}
                            sm={6}
                            md={3}
                            className={classes.toolList}
                        >
                            <List>
                            
                            {_.slice(tools,(toolsColListSize*column),(toolsColListSize*(column+1))).map((tool,idx) => {
                                return (
                                    <ListItem key={idx}>
                                        <ListItemText
                                            primary={tool.name}
                                        />
                                    </ListItem>
                                )
                            })}
                            </List>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    </Box>
  )
}

export default Skills
