import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  root: {
  }
}))

const Personal = ({ data }) => {
  const classes = useStyles()

  return (
    <Box className= {classes.root}>
    Personal
    </Box>
  )
}

export default Personal
