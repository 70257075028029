import React from 'react'
import { graphql } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { makeStyles } from '@material-ui/core/styles'

import colors from '../components/theme/colors'
import Typography from '@material-ui/core/Typography';

import Skills from '../components/Skills'
import Work from '../components/Work'
import AdditionalWork from '../components/AdditionalWork'
import Education from '../components/Education'
import Personal from '../components/Personal'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: colors.base.white,
  },
  purple: {
    color: colors.base.white,
    backgroundColor: colors.purple["0"]
  },
  blue: {
    color: colors.base.white,
    backgroundColor: colors.blue["0"]
  },
  pink: {
    color: colors.base.white,
    backgroundColor: colors.pink["0"]
  },
  grey: {
    color: colors.base.white,
    backgroundColor: colors.grey["0"]
  },
  orange: {
    color: colors.base.white,
    backgroundColor: colors.orange["0"]
  },
  heroContainer: {
    minHeight: '300px',
  },
  heroBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5%',
    textAlign: 'center',
    background: 'rgba(0,0,0,0.7)',
    height: '100%'
  },
  heroLogo: {

  },
  heroText: {
    marginBottom: '2rem',
  },
  heroImage: {
    //minHeight: '100%',
    height: '100%'
  },
  pageBox: {
    minHeight: '350px',
    
  },
}));


const IndexPage = ({data}) => {

  const classes = useStyles()

  return(
    <Layout>
      <SEO title="Graham Daw" />
      <Skills />
      <Work />
      <AdditionalWork />
      <Education />
      <Personal />
    </Layout>
  ) 
}

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "main-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1380) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
